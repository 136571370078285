<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4 create-new'>
      <b-col cols='12'>
        <i class='fa fa-backward'></i>
        <router-link :to="{name: SubscriptionPath.name}"> Subscription Offers</router-link>
      </b-col>
      <b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
          <div class='card'>
            <div class='bg-success p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Create New Subscription Offer</h5>
            </div>
            <div class='card-body'>
              <b-row>
                <b-col lg='12'>
                  <b-form-group label='Offers Name'>
                    <b-form-input id='price' v-model='form.long_name' type='text' placeholder='' required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Brand'>
                    <multiselect label='name' track-by='id' placeholder='Enter Brand' open-direction='bottom'
                      required='required' :searchable='true' :close-on-select='true' :options-limit='10'
                      :multiple='false' v-model='form.brand' @search-change='brandFind' :loading='isBrandSearch'
                      :options='brandSearchOptions' @input='offerNameHandler'>
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Subscription Type' label-for='subscription_type'>
                    <b-form-select id='subscription_type' v-model='form.subscription_type'
                      :options='subscriptionTypeSearchOptions' @input='offerNameHandler' required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Status'>
                    <b-form-select id="offer_status" v-model="form.offer_status" :options="offerStatusOptions" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Quantity'>
                    <b-form-input id='price' placeholder='1-4 edition or 1-4 month' v-model='form.quantity'
                      type='number' min='1' @input='offerNameHandler' required>
                    </b-form-input>
                    <dfn>
                      total edition subscription
                    </dfn>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Period' v-if='form.subscription_type != 1'>
                    <b-form-input id='price' placeholder='1-12 Month, 1-4 week' v-model='form.period' type='number'
                      min='1' @input='offerNameHandler' required>
                    </b-form-input>
                    <dfn>
                      range period subscription
                    </dfn>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Web Price USD'>
                    <b-form-input id='price' v-model='form.price_usd' type='number' step='any'
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Web Price IDR'>
                    <b-form-input id='price' v-model='form.price_idr' type='number' step='any'
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='6'>
                  <b-form-group label='IOS Tier'>
                    <multiselect v-model='form.ios_tier' label='tier_code' track-by='tier_code' placeholder='Ios Tier'
                      open-direction='bottom' :options='iosTierSearchOptions' :searchable='true'
                      :loading='isIosTierSearch' :close-on-select='true' :multiple='false' @search-change='iosTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.ios_tier ? form.ios_tier.tier_price : 0 }}
                      <br>
                      IDR : {{ form.ios_tier ? form.ios_tier.price_idr : 0 | toCurrencyID }}
                    </dfn>
                  </b-form-group>
                </b-col>

                <b-col lg='6'>
                  <b-form-group label='Android Tier'>
                    <multiselect v-model='form.android_tier' label='tier_code' track-by='tier_code'
                      placeholder='Ios Tier' open-direction='bottom' :options='androidTierSearchOptions'
                      :searchable='true' :loading='isAndroidTierSearch' :close-on-select='true' :multiple='false'
                      @search-change='androidTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.android_tier ? form.android_tier.tier_price : 0 }}
                      <br>
                      IDR : {{ form.android_tier ? form.android_tier.price_idr : 0 | toCurrencyID }}
                    </dfn>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-form-checkbox v-model='form.is_free' :value=true unchecked-value='false'> Free Offer
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button variant='primary' type='submit'>create</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SubscriptionPath } from '../../router/marketing';
import constant from '../../store/constant';

const subsType = Object.entries(constant.OFFER_SUBS_TYPE)
  .map(val => ({ text: val[0], value: val[1] }));

export default {
  name: 'newSubscriptionOffer',
  data() {
    return {
      SubscriptionPath,
      debounce: null,
      form: {
        name: null,
        long_name: null,
        subscription_type: null,
        brand: null,
        price_usd: 0,
        price_idr: 0,
        ios_tier: null,
        android_tier: null,
        is_free: false,
        quantity: 1,
        period: 1,
        offer_status: null,
      },
      subscriptionTypeSearchOptions: [
        { text: 'Select Type', value: null },
        ...subsType,
      ],
      offerStatusOptions: [
        { value: null, text: 'Select Status'},
        { value: constant.OFFER_STATUS.READY_FOR_SALE, text: 'On Sale' },
        { value: constant.OFFER_STATUS.NOT_FOR_SALE, text: 'Not Sale' },
      ],
      isBrandSearch: false,
      brandSearchOptions: [],
      isIosTierSearch: false,
      iosTierSearchOptions: [],
      isAndroidTierSearch: false,
      androidTierSearchOptions: [],
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.offersSubscription.isError,
      isLoading: (state) => state.offersSubscription.isLoading,
      errorMessage: (state) => state.offersSubscription.errorMessage,
      successMessage: (state) => state.offersSubscription.successMessage,
      item: (state) => state.offersSubscription.item,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);

      // reset form
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
  created() {
  },
  methods: {
    ...mapActions('brands', ['searchBrands']),
    ...mapActions('offersSubscription', ['postSubcriptionOffers']),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTier',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: 'searchIosTier',
    }),

    onSubmit(event) {
      event.preventDefault();
      const data = this.form;
      if (!data.brand?.id) {
        this.messageAlert('error', 'brand not selected', 3000)
        return;
      }
      if (!data.android_tier?.id) {
        this.messageAlert('error', 'android tier not selected!', 5000);
        return;
      }
      if (!data.ios_tier?.id) {
        this.messageAlert('error', 'IOS tier not selected!', 5000);
        return;
      }
      const payload = {
        name: data.name,
        long_name:data.long_name,
        brand_id: data.brand.id,
        price_usd: data.price_usd,
        price_idr: data.price_idr,
        is_free: data.is_free,
        is_active: true,
        offer_status: data.offer_status,
        android_tier_id: data.android_tier.id,
        ios_tier_id: data.ios_tier.id,
        subscription_type: data.subscription_type,
        quantity: data.quantity,
      };
      this.postSubcriptionOffers(payload).then(() =>{
        this.form.name = ''
        this.form.long_name = '';
      })
    },

    onReset() { },

    brandFind(query) {
      if (!query) return;

      this.isBrandSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          type: constant.ITEM_TYPES.MAGAZINE.toLowerCase(),
          name: query,
          limit: 10,
        };
        this.searchBrands(payload).then((response) => {
          this.brandSearchOptions = response.data.data.rows;
          this.isBrandSearch = false;
        }).catch(() => {
          this.isBrandSearch = false;
        });
      }, 600);
    },

    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          this.iosTierSearchOptions = response.data.data.rows;
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },

    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query }).then((response) => {
          this.androidTierSearchOptions = response.data.data.rows;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    offerNameHandler() {
      let brandName = this.form?.brand?.name || '';
      let quantity = parseInt(this.form?.quantity) || '';
      let period = parseInt(this.form?.period) || '';
      let type = this.form?.subscription_type || '';

      if (quantity < 1) quantity = 1;
      if (period < 1) period = 1;

      if (type == constant.OFFER_SUBS_TYPE.EDITION) {
        type = 'month';
        period = '';
      } else if (type == constant.OFFER_SUBS_TYPE.WEEKLY) {
        type = 'weeks';
      } else if (type == constant.OFFER_SUBS_TYPE.MONTHLY) {
        type = 'months';
      }

      this.form.long_name = `${brandName} ${quantity} Edition / ${period} ${type} Subscription`
        .replace(/ +(?= )/g, '');
      this.form.name = `${quantity} Edition / ${period} ${type}`
        .replace(/ +(?= )/g, '');
    },
  },
  filters: {
    toCurrencyID: function (val) {
      return parseInt(val).toLocaleString('id');
    }
  }
};
</script>
